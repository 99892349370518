import { getCookie } from "typescript-cookie";

type FetchParams = Parameters<(typeof window)["fetch"]>;
type FetchRequestHeaders = NonNullable<NonNullable<FetchParams[1]>["headers"]>;

export const API_BASE_PATH = "/api/v1";

function metaContent(name: string) {
  return document.head.querySelector<HTMLMetaElement>(`meta[name="${name}"]`)?.content;
}

function getCSRFToken() {
  const name = metaContent("csrf-param");
  if (name) {
    return getCookie(name) || metaContent("csrf-token");
  }
}

export function getPatientBaseUrl(patientId: number): string {
  return `${API_BASE_PATH}/patients/${patientId}`;
}

export function getOdontogramUrl(patientId: number): string {
  return `${getPatientBaseUrl(patientId)}/odontogram`;
}

export async function fetchJSON<R>(resource: FetchParams[0], options?: FetchParams[1]): Promise<R> {
  const headers: FetchRequestHeaders = {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    ...options?.headers,
  };
  const token = options?.method !== "GET" && getCSRFToken();
  if (token) {
    (headers as Record<string, string>)["X-CSRF-Token"] = token;
  }
  const response = await fetch(resource, {
    ...options,
    headers,
  });
  if (response.ok) {
    return response.json() as Promise<R>;
  }
  throw new Error(`Failed response ${response.status}`);
}
