import { createSignal, onMount, ErrorBoundary as SolidErrorBoundary, type JSX } from "solid-js";

import { captureException } from "~/observability";

function ErrorHandler(props: { errorCount: number; error: unknown; onReset: () => void }) {
  onMount(() => {
    captureException(props.error);

    if (props.errorCount < 3) {
      setTimeout(props.onReset, 2 ** props.errorCount * 500);
    }
  });
  return null;
}

export function ErrorBoundary(props: { children: JSX.Element }) {
  const [errorCount, setErrorCount] = createSignal(0);
  return (
    <SolidErrorBoundary
      fallback={(error: unknown, reset) => (
        <ErrorHandler
          errorCount={errorCount()}
          error={error}
          onReset={() => {
            setErrorCount((c) => c + 1);
            reset();
          }}
        />
      )}
    >
      {props.children}
    </SolidErrorBoundary>
  );
}
