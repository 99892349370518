// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { init, setUser, captureException as sentryCaptureException } from "@sentry/browser";

function getMetaUserInfo() {
  const content = document.head.querySelector<HTMLMetaElement>('meta[name="user-info"]')?.content;
  try {
    return content && (JSON.parse(content) as { id: number; email: string });
  } catch {
    // Ignore json parse errors
  }
}

let captureFn: (...params: Parameters<typeof sentryCaptureException>) => void = (error, hint) => {
  console.error(error, hint);
};

if (import.meta.env.PUBLIC_SENTRY_DSN) {
  init({
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: import.meta.env.PUBLIC_RELEASE_GIT_HASH,
    beforeSend(event, hint) {
      const message = (hint.originalException as null | { message?: string })?.message;
      switch (message) {
        case "The user aborted a request.": {
          console.warn(message);
          return null;
        }
      }
      return event;
    },
  });
  captureFn = sentryCaptureException;

  const userInfo = getMetaUserInfo();
  if (userInfo) {
    setUser(userInfo);
  }
}

export const captureException = captureFn;
