import type { Component } from "solid-js";
import { render as solidRender, type MountableElement } from "solid-js/web";

import { ErrorBoundary } from "./error_boundary";
import { QueryClientProvider } from "./query_client";

export function TwelveEntrypointWrapper(props: { entry: Component }) {
  return (
    <QueryClientProvider>
      <ErrorBoundary>
        <props.entry />
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export function render(entry: Component, container: MountableElement) {
  const startingLocation = document.location.href;
  const dispose = solidRender(() => <TwelveEntrypointWrapper entry={entry} />, container);
  const handleVisit = (event: Event) => {
    const { url } = (event as CustomEvent<{ url: string }>).detail;
    if (url !== startingLocation) {
      dispose();
      document.documentElement.removeEventListener("turbo:visit", handleVisit);
    }
  };
  document.documentElement.addEventListener("turbo:visit", handleVisit);
}
