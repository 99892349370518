import type { JSX } from "solid-js";
import { QueryClient, QueryClientProvider as InnerClientProvider } from "@tanstack/solid-query";

const CLIENT = new QueryClient({
  defaultOptions: {
    queries: { reconcile: "id" },
  },
});

export function QueryClientProvider(props: { children: JSX.Element }) {
  return <InnerClientProvider client={CLIENT}>{props.children}</InnerClientProvider>;
}
